<template>
  <div class="container">
    <h2>{{ this.loggedUser.username }}</h2>
    <h4>{{ dayWeek }}, {{ day }} {{ month }} {{ year }}</h4>
    <div>
      <div class="dashboard">
        <div class="card-container">
          <div class="card">
              <img
                src="https://play-lh.googleusercontent.com/mOMVlWK_cLLdOOO3XUWiw1zFf7FO15f_ys2XmcYKJLOMTHF-eGtQddXE-wYDSd9kd5Q"
                class="card-img-top"
                alt=""
              />
              <div class="card-body">
                <h5 class="card-title">Definições</h5>
                <p class="card-text">Alterar alguma caraterísticas do backoffice</p>
                <a href="/settings" class="btn btn-primary">Abrir</a>
              </div>
            </div>
            <div class="card">
              <img
                src="https://controlefinanceiro.granatum.com.br/wp-content/uploads/2022/05/header-relatorios.png"
                class="card-img-top"
                alt=""
              />
              <div class="card-body">
                <h5 class="card-title">Relatórios</h5>
                <p class="card-text">CSV relatórios</p>
                <a href="/reports" class="btn btn-primary">Abrir</a>
              </div>
            </div>
            <div class="card">
              <img
                src="https://images.shiksha.com/mediadata/shikshaOnline/mailers/2022/naukri-learning/what-is/What-is-sales.jpg"
                class="card-img-top"
                alt=""
              />
              <div class="card-body">
                <h5 class="card-title">Relatório de Vendas</h5>
                <p class="card-text">Ver Relatórios de Vendas</p>
                <a href="/salesReportCountry" class="btn btn-primary">Ver mais</a>
              </div>
            </div>
            <div class="card">
              <img
                src="https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fimages.saymedia-content.com%2F.image%2Ft_share%2FMTc2MjkyNzM0NjI4MzQwOTA5%2Fhow-to-write-a-business-report.jpg&f=1&nofb=1&ipt=11e8238444a7820b63b5ef0001cc613d0c20f803e71fc164fb6bc36f3d5600f5&ipo=images"
                class="card-img-top"
                alt=""
              />
              <div class="card-body">
                <h5 class="card-title">Relatório de Envios</h5>
                <p class="card-text">Ver relatório de envios</p>
                <a href="/shippingReports" class="btn btn-primary">Abrir</a>
              </div>
            </div>
            <div class="card">
              <img
                src="https://w7.pngwing.com/pngs/478/547/png-transparent-coupon-discounting-discounts-and-allowances-promotion-payment-desconto-text-service-payment.png"
                class="card-img-top"
                alt=""
              />
              <div class="card-body">
                <h5 class="card-title">Descontos</h5>
                <p class="card-text">Criar, editar e eliminar descontos</p>
                <a href="/discounts" class="btn btn-primary">Abrir</a>
              </div>
            </div>
            <div class="card">
              <img
                src="https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fbenmorgancopy.com%2Fwp-content%2Fuploads%2F2019%2F07%2Fphoto_86836_20170320-e1562727088552.jpg&f=1&nofb=1&ipt=afa7da568e6662cd3af78c91dedd0f030bdefc3773a70772e85d5b61f0edd089&ipo=images"
                class="card-img-top"
                alt=""
              />
              <div class="card-body">
                <h5 class="card-title">Feeds</h5>
                <p class="card-text">Criar feeds e categorias</p>
                <a href="/feedsCountry" class="btn btn-primary">Abrir</a>
              </div>
            </div>
            <div class="card">
              <img
                src="https://www.yesstyle.com/blog/wp-content/uploads/2023/01/skincare_featured.webp"
                class="card-img-top"
                alt=""
              />
              <div class="card-body">
                <h5 class="card-title">Marcas</h5>
                <p class="card-text">Editar Marcas</p>
                <a href="/brands" class="btn btn-primary">Abrir</a>
              </div>
            </div>
            <div class="card">
              <img
                src="https://www.melhoresdestinos.com.br/wp-content/uploads/2021/04/mapa-mundo-bandeiras-820x407.png"
                class="card-img-top"
                alt=""
              />
              <div class="card-body">
                <h5 class="card-title">Países</h5>
                <p class="card-text">Editar tracking link</p>
                <a href="/countries" class="btn btn-primary">Abrir</a>
              </div>
            </div>
            <div class="card">
              <img src="https://www.azernews.az/media/pictures/managing-users.jpg" class="card-img-top" alt="" />
              <div class="card-body">
                <h5 class="card-title">Utilizadores</h5>
                <p class="card-text">Todos os utilizadores da Plataforma</p>
                <a href="/users" class="btn btn-primary">Abrir</a>
              </div>
            </div>
            
            <div class="card">
              <img
                src="https://kinsta.com/pt/wp-content/uploads/sites/3/2019/11/logs-deerros-o-wordpress.png"
                class="card-img-top"
                alt=""
              />
              <div class="card-body">
                <h5 class="card-title">Logs & Dashboard</h5>
                <p class="card-text">Ver registo dos movimentos realizados e processos automáticos</p>
                <a href="/logs" class="btn btn-primary">Abrir</a>
              </div>
            </div>
            
            <div class="card">
              <img
                src="https://www.shutterstock.com/image-vector/upload-document-uploading-file-flat-260nw-2147555307.jpg"
                class="card-img-top"
                alt=""
              />
              <div class="card-body">
                <h5 class="card-title">Importar Ficheiros</h5>
                <p class="card-text">Sistema para importar ficheiros csv</p>
                <a href="/importFiles" class="btn btn-primary">Abrir</a>
              </div>
            </div>
            
            <!-- <div class="card">
              <img src="https://kinsta.com/pt/wp-content/uploads/sites/3/2019/11/logs-deerros-o-wordpress.png" class="card-img-top" alt="" />
              <div class="card-body">
                <h5 class="card-title">Retornar Encomenda</h5>
                <p class="card-text">Retornar</p>
                <a class="btn btn-primary" v-b-modal.modal-4>Retornar</a>
              </div>
            </div> -->
            
        </div>
      </div>
    </div>
    <b-modal ref="modal_cancel" id="modal-4" title="Duplicar Encomenda" hide-footer>
      <form v-on:submit.prevent="returned()">
        <div class="form-group">
          <label for="contactInput">Número Encomenda:</label>
          <input type="text" class="form-control" id="contactInput" v-model="order" required />
        </div>
        <div class="form-group">
          <label for="contactInput">Comentário:</label>
          <input type="text" class="form-control" id="contactInput" v-model="comment" required />
        </div>
        <div id="btnUpdateAddress">
          <button class="btn btn-primary">Confirmar</button>
        </div>
      </form>
    </b-modal>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
  data: function () {
    return {
      loggedUser: [],
      totalProcessing: 0,
      totalPicking: 0,
      percProcessing: 0,
      dayWeek: '',
      day: '',
      month: '',
      year: '',
      order: '',
      comment: '',
      allMonths: [
        'Janeiro',
        'Fevereiro',
        'Março',
        'Abril',
        'Maio',
        'Junho',
        'Julho',
        'Agosto',
        'Setembro',
        'Outobro',
        'Novembro',
        'Dezembro',
      ],
    };
  },
  methods: {
    async getUserInformation() {
      this.loggedUser = this.getLoggedUser;
      if (this.loggedUser.length == 0) {
        let token = localStorage.getItem('token');
        let jwt = token.split('.')[1];
        this.loggedUser = JSON.parse(window.atob(jwt));
      }
      console.log(this.loggedUser.username);
    },
    getDay() {
      let date = new Date();
      let weekDay = date.getDay();
      this.day = date.getDate();
      let mon = date.getMonth();
      this.year = date.getFullYear();
      switch (weekDay) {
        case 1:
          this.dayWeek = 'Segunda-Feira';
          break;
        case 2:
          this.dayWeek = 'Terça-Feira';
          break;
        case 3:
          this.dayWeek = 'Quarta-Feira';
          break;
        case 4:
          this.dayWeek = 'Quinta-Feira';
          break;
        case 5:
          this.dayWeek = 'Sexta-Feira';
          break;
        case 6:
          this.dayWeek = 'Sábado';
          break;
        case 7:
          this.dayWeek = 'Domingo';
          break;
      }
      this.month = this.allMonths[mon];
    },
    async returned() {
      this.$refs['modal_cancel'].hide();
      await this.$swal({
        title: 'Duplicar Encomenda',
        text: `Quer duplicar a encomenda ${this.order}?`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim, confirmo!',
        cancelButtonText: 'Cancelar',
      }).then(async (result) => {
        if (result.isConfirmed) {
          await this.$swal({
            title: 'Quer manter a fatura original?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não',
          }).then(async (result) => {
            let invoice = false;
            if (result.isConfirmed) {
              invoice = true;
            }
            try {
              await this.$store.dispatch('backOrder', {
                id: this.order,
                comment: this.comment,
                invoice: invoice,
              });
            } catch (err) {
              alert(err);
            }
          });
        }
      });
    },
  },
  created() {
    this.getUserInformation();
    this.getDay();
  },
  computed: {
    ...mapGetters(['getLoggedUser']),
  },
};
</script>
<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap');

h2 {
  margin: 50px 50px 0px 0px;
}
h4 {
  margin: 0px 50px 50px 0px;
}

.btn {
  margin-top: 10px;
  float: right;
  background: rgb(255, 147, 147);
  border: none;
  box-shadow: none !important;
}
.btn:hover,
.btn:focus {
  background: rgba(255, 147, 147, 0.658);
  border: white;
}

.card {
  margin-top: 10px;
  border-radius: 10px;
}
img {
  height: 200px;
  object-fit: cover;
  object-position: 0% 50;
}

@media (max-width: 767px) {
  body {
    padding: 10px;
  }

  .body {
    width: 100%;
    height: 100%;
  }
}

.dashboard {
  padding: 10px;
  margin-bottom: 20px;
}

.card-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
}

.container{
  max-width: 90%;
}
</style>
